<template>
  <div class="box_1">
    <div class="header">
      <div class="text">图表导航</div> 
    </div>

    <div class="content_box">
      <div class="content_item_box">
        <dv-border-box-8 dur="6" class="border_radius_10">
          <div class="title"><span>巴林右旗</span></div>

          <div class="item_box">
            <div 
              class="item" 
              v-for="(item, index) in list1" :key="index"
              @click="goToPage(1, index)"
              >
              {{ item.name }}
            </div>
          </div>
        </dv-border-box-8>
        
      </div>

      <div class="content_item_box">
        <dv-border-box-8 dur="6">
          <div class="title"><span>巴林右旗大板镇</span></div>
          <div class="item_box">
            <div 
              class="item" 
              v-for="(item, index) in list3" :key="index"
              @click="goToPage(3, index)"
              >
              {{ item.name }}
            </div>
          </div>
        </dv-border-box-8>
      </div>

      <div class="content_item_box">
        <dv-border-box-8 dur="6">
          <div class="title"><span>大板镇嘎查</span></div>
          <div class="item_box">
            <div 
              class="item" 
              v-for="(item, index) in list5" :key="index"
              @click="goToPage(5, index)"
              >
              {{ item.name }}
            </div>
          </div>
        </dv-border-box-8>
      </div>

      <div class="content_item_box">
        <dv-border-box-8 dur="6">
          <div class="title"><span>其他</span></div>
          <div class="item_box">
            <div 
              class="item" 
              v-for="(item, index) in list6" :key="index"
              @click="goToPage(6, index)"
              >
              {{ item.name }}
            </div>
          </div>
        </dv-border-box-8>  
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {
        list1: [
          { name: '首页', path: '/' },
          { name: '首页2', path: '/home-2' },
          { name: '首页3', path: '/home-3' },
          { name: '农业统计', path: '/bigscreen/countyPage' },
          { name: '牧业统计', path: '/bigscreen/countyPage2' },
          { name: '农业排行', path: '/bigscreen/countyFarmal' },
          { name: '牧业排行', path: '/bigscreen/countyAnimal' },
          { name: '人口统计', path: '/bigscreen/personState巴林右旗人口统计' },
          { name: '机械化统计', path: '/bigscreen/mashineState巴林右旗机械化统计' },
          { name: '土地流转', path: '/bigscreen/tuDiLiuZhuan' },
          { name: '土地确权', path: '/bigscreen/tuDiQueQuan' },
          { name: '育种信息', path: '/bigscreen/yuZhongJiLu' },
          { name: '防疫信息', path: '/bigscreen/fangYiJiLu' },
          { name: '防疫呼叫', path: '/bigscreen/fangYiHuJiao' },
          { name: '工作统计', path: '/bigscreen/gongZuoTongJi' },
          { name: '企业信息', path: '/bigscreen/qiYe' },
        ],

        list3: [
          { name: '农牧业统计', path: '/bigscreen/villagePage大板镇' },
          { name: '农业统计', path: '/bigscreen/villageFarmal大板镇' },
          { name: '牧业统计', path: '/bigscreen/villageAnimal大板镇' },
          { name: '人口统计', path: '/bigscreen/personState巴林右旗大板镇人口统计' },
          { name: '机械化统计', path: '/bigscreen/mashineState巴林右旗大板镇机械化统计' },
          { name: '防疫统计', path: '/bigscreen/villageFangYiTongJi大坂镇' },
          // { name: '防疫点', path: '/bigscreen/dianBiaoJi' },
        ],

        list5: [
          { name: '浩饶沁嘎查农业', path: '/bigscreen/hamletPage浩饶沁嘎查' },
          { name: '浩饶沁嘎查牧业', path: '/bigscreen/hamletAnimalPage浩饶沁嘎查' },
          { name: '红旗嘎查农业', path: '/bigscreen/hamletPage红旗嘎查' },
          { name: '红旗嘎查牧业', path: '/bigscreen/hamletAnimalPage红旗嘎查' },
          { name: '大冷村农业', path: '/bigscreen/hamletPage大冷村' },
          { name: '大冷村牧业', path: '/bigscreen/hamletAnimalPage大冷村' },
          { name: '古力古台嘎查农业', path: '/bigscreen/hamletPage古力古台嘎查' },
          { name: '古力古台嘎查牧业', path: '/bigscreen/hamletAnimalPage古力古台嘎查' },
          { name: '吉布吐嘎查农业', path: '/bigscreen/hamletPage吉布吐嘎查' },
          { name: '吉布吐嘎查牧业', path: '/bigscreen/hamletAnimalPage吉布吐嘎查' },
          { name: '西哈嘎查农业', path: '/bigscreen/hamletPage西哈嘎查' },
          { name: '西哈嘎查牧业', path: '/bigscreen/hamletAnimalPage西哈嘎查' },
          { name: '温根吐嘎查农业', path: '/bigscreen/hamletPage温根吐嘎查' },
          { name: '温根吐嘎查牧业', path: '/bigscreen/hamletAnimalPage温根吐嘎查' },
          { name: '十家子嘎查农业', path: '/bigscreen/hamletPage十家子嘎查' },
          { name: '十家子嘎查牧业', path: '/bigscreen/hamletAnimalPage十家子嘎查' },
          { name: '苏艾力嘎查农业', path: '/bigscreen/hamletPage苏艾力嘎查' },
          { name: '苏艾力嘎查牧业', path: '/bigscreen/hamletAnimalPage苏艾力嘎查' },
          { name: '昭胡都格嘎查农业', path: '/bigscreen/hamletPage昭胡都格嘎查' },
          { name: '昭胡都格嘎查牧业', path: '/bigscreen/hamletAnimalPage昭胡都格嘎查' },
        ],

        list6: [
          { name: '牧户详情', path: '/bigscreen/muHuPage11' },
          { name: '畜牛详情', path: '/bigscreen/oxDetail733' },
          { name: '商品信息', path: '/bigscreen/productPage' },
          { name: '订单信息', path: '/bigscreen/orderPage' },
          { name: '饲草商列表', path: '/bigscreen/siCaoShangPage' },
          { name: '兽医列表', path: '/bigscreen/siCaoShangPage' },
          { name: '示范点牧户', path: '/demo-makido全部' },
          { name: '牧户管理', path: '/bigscreen/admin' },
          // { name: '那林柴达木牧场', path: '/personal-makido' },
          
        ],
      }
    },
    mounted() {

    },
    methods: {
      // 跳转页面
      goToPage(val, index) {
        let path = ''
        if (val == 1) {
          path = this.list1[index].path
        } else if (val == 2) {
          path = this.list2[index].path
        } else if (val == 3) {
          path = this.list3[index].path
        } else if (val == 4) {
          path = this.list4[index].path
        } else if (val == 5) {
          path = this.list5[index].path
        } else {
          path = this.list6[index].path
        }
        
        this.$router.push({
          path: path
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }

  .box_1 { background-size: 100% 100%; }
  .header { background-size: 100% 100%; }

  .content_box {
    width: 100%;
    height: calc(100% - 100px);
    padding: 10px;

    .content_item_box {
      width: 20% !important;
      height: 100%;
      // background-image: url('../../../../assets/images//chart-box/img3.png');
      // background-size: 100% 100%;

      .title { 
        width: 100%; 
        height: 6%; 
        text-align: center;
        display: flex;
        align-items: center;

        span {
          width: 100%;
          font-size: 18px; 
          font-weight: bold; 
          color: #82fefe; 
          cursor: pointer; 

        }
      }

      .item_box {
        width: 100%;
        height: 94%;
        overflow: auto;
        .item { width: 100%; text-align: center; color: #fff; cursor: pointer; margin-bottom: 5px; }
        .item:hover { color: #82fefe; }
      }
      .item_box::-webkit-scrollbar {
        width: 0px;
        height: 100%;
        background-color: #f5f5f5;
      }

      .item_box::-webkit-scrollbar-thumb {           
        border-radius: 10px;
        background: #2366fc;
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>